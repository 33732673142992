import React, { useState, useEffect, useRef, useMemo, useReducer, useLayoutEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Grid, Segment, Button, Input, Dropdown, Checkbox, Message, Label, Divider, Header, Icon, Accordion } from 'semantic-ui-react';
import Papa from 'papaparse';
import './legislative-bill-tracker.css';

const messages = defineMessages({
  title: {
    id: 'legislative-bill-tracker-title',
    defaultMessage: 'Texas Legislative Bills - 89th Regular Session (2025)',
  },
  filterByCategoryTitle: {
    id: 'legislative-bill-tracker-filter-by-category-title',
    defaultMessage: 'Filter Bills by Category',
  },
  filterByPriorityTitle: {
    id: 'legislative-bill-tracker-filter-by-priority-title',
    defaultMessage: 'Filter by Priority',
  },
  filterByStageTitle: {
    id: 'legislative-bill-tracker-filter-by-stage-title',
    defaultMessage: 'Filter by Stage',
  },
  sortByTitle: {
    id: 'legislative-bill-tracker-sort-by-title',
    defaultMessage: 'Sort By',
  },
  sortingByText: {
    id: 'legislative-bill-tracker-sorting-by-text',
    defaultMessage: 'Sorting by {sort} in {direction} order',
  },
  noResultsMessage: {
    id: 'legislative-bill-tracker-no-results-message',
    defaultMessage: 'No bills match your selected filters. Please adjust your filter settings to see more bills.',
  },
  keyHighlightsLabel: {
    id: 'legislative-bill-tracker-key-highlights-label',
    defaultMessage: 'Key Highlights:',
  },
  lastActionLabel: {
    id: 'legislative-bill-tracker-last-action-label',
    defaultMessage: 'Last Action:',
  },
});

// Define additional styles for the elegant filters
const filterStyles = {
  filtersContainer: {
    background: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    marginBottom: '25px'
  },
  filterSection: {
    marginBottom: '20px'
  },
  filterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  filterTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0'
  },
  activeFiltersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '20px',
    padding: '15px',
    background: '#f8f9fa',
    borderRadius: '6px',
    alignItems: 'center'
  },
  filterChip: {
    display: 'inline-flex',
    alignItems: 'center',
    background: '#e6f2ff',
    padding: '6px 12px',
    borderRadius: '100px',
    fontSize: '13px',
    border: '1px solid #cce5ff',
    cursor: 'pointer',
    transition: 'all 0.2s ease'
  },
  activeFilterChip: {
    background: '#007bff',
    color: 'white',
    border: '1px solid #0062cc'
  },
  clearButton: {
    background: 'transparent',
    border: '1px solid #ddd',
    borderRadius: '6px',
    padding: '6px 15px',
    fontSize: '13px',
    cursor: 'pointer',
    marginLeft: 'auto'
  },
  activeFiltersSummary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    background: '#f0f4f8',
    borderRadius: '6px',
    marginTop: '10px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.05)'
  },
  pillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginBottom: '5px'
  }
};

// Initial state for the tracker
const initialState = {
  allBills: [],
  isLoading: true,
  filters: {
    category: 'all',
    priority: 'all',
    stage: 'all',
    isOpen: false
  },
  sorting: {
    field: 'impact',
    direction: 'desc',
    isOpen: false
  }
};

// Action types
const ACTIONS = {
  SET_BILLS: 'set_bills',
  SET_LOADING: 'set_loading',
  SET_FILTER: 'set_filter',
  SET_SORT: 'set_sort',
  CLEAR_FILTERS: 'clear_filters',
  TOGGLE_FILTERS: 'toggle_filters',
  TOGGLE_SORT: 'toggle_sort'
};

// Reducer function
function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_BILLS:
      return { ...state, allBills: action.payload };
    
    case ACTIONS.SET_LOADING:
      return { ...state, isLoading: action.payload };
    
    case ACTIONS.SET_FILTER:
      return { 
        ...state, 
        filters: { 
          ...state.filters, 
          [action.payload.name]: action.payload.value 
        }
      };
    
    case ACTIONS.SET_SORT:
      return { 
        ...state, 
        sorting: { 
          ...state.sorting, 
          [action.payload.name]: action.payload.value 
        } 
      };
    
    case ACTIONS.CLEAR_FILTERS:
      return { 
        ...state, 
        filters: { 
          category: 'all',
          priority: 'all',
          stage: 'all' 
        },
        sorting: {
          field: 'impact',
          direction: 'desc'
        }
      };
    
    case ACTIONS.TOGGLE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          isOpen: !state.filters.isOpen
        }
      };
    
    case ACTIONS.TOGGLE_SORT:
      return {
        ...state,
        sorting: {
          ...state.sorting,
          isOpen: !state.sorting.isOpen
        }
      };
    
    default:
      return state;
  }
}

// Filter options data
const filterOptions = {
  categories: [
    { id: 'all', name: 'All Categories' },
    { id: 'Business-Taxation', name: 'Business Taxation' },
    { id: 'Business-Courts', name: 'Business Courts' },
    { id: 'Financial-Institutions', name: 'Financial Institutions' },
    { id: 'Business-Entities', name: 'Business Entities' },
    { id: 'Foreign-Property-Ownership', name: 'Foreign Property Ownership' },
    { id: 'Real-Estate', name: 'Real Estate' },
    { id: 'Securities', name: 'Securities' },
    { id: 'Business-Liability', name: 'Business Liability' },
    { id: 'AI-and-Technology', name: 'AI and Technology' }
  ],
  priorities: [
    { id: 'all', name: 'All Bills' },
    { id: 'priority-action', name: 'Immediate Action Needed' },
    { id: 'upcoming-action', name: 'Upcoming Action' },
    { id: 'normal', name: 'Normal' }
  ],
  stages: [
    { id: 'all', name: 'All Stages' },
    { id: 'stage-1', name: 'Stage 1: Filed' },
    { id: 'stage-2', name: 'Stage 2: Out of First Committee' },
    { id: 'stage-3', name: 'Stage 3: Voted by House/Senate' },
    { id: 'stage-4', name: 'Stage 4: Out of Second Committee' },
    { id: 'stage-5', name: 'Stage 5: Voted by Senate/House' },
    { id: 'stage-6', name: 'Stage 6: Governor Action' },
    { id: 'stage-7', name: 'Stage 7: Bill Becomes Law' }
  ],
  sortOptions: [
    { key: 'impact', text: 'Business Impact Level', value: 'impact' },
    { key: 'stage', text: 'Legislative Stage', value: 'stage' },
    { key: 'lastActionDate', text: 'Last Action Date', value: 'lastActionDate' },
    { key: 'date', text: 'Bill Filing Date', value: 'date' },
    { key: 'title', text: 'Bill Number/Title', value: 'title' }
  ]
};

// Sample bill data
const sampleBills = [
  {
    id: "HB1566",
    title: "HB1566",
    link: "https://capitol.texas.gov/BillLookup/History.aspx?LegSess=89R&Bill=HB1566",
    impact: "high",
    impactValue: 3,
    categories: ["Foreign-Property-Ownership"],
    stage: "stage-1",
    filingDate: new Date("2024-12-10"),
    filingInfo: "Filed by Patterson on 12/10/2024",
    priority: "priority-action",
    highlights: [
      "Prohibits foreign adversary entities from purchasing Texas agricultural land",
      "Restricts foreign ownership near military facilities and critical infrastructure",
      "Creates state review board for foreign real estate investments",
      "Requires disclosure of foreign ownership in all property transactions",
      "Establishes civil and criminal penalties for non-compliance"
    ],
    actionMessage: "⚠️ IMMEDIATE ACTION: Public hearing in 6 days (3/20/2025)",
    stageName: "Stage 1: Filed",
    progress: 14,
    lastAction: "Scheduled for public hearing in House State Affairs Committee on 3/20/2025"
  },
  {
    id: "SB263",
    title: "SB263",
    link: "https://capitol.texas.gov/BillLookup/History.aspx?LegSess=89R&Bill=SB263",
    impact: "high",
    impactValue: 3,
    categories: ["Business-Taxation"],
    stage: "stage-3",
    filingDate: new Date("2024-11-12"),
    filingInfo: "Filed by Perry on 11/12/2024",
    priority: "priority-action",
    highlights: [
      "Eliminates franchise tax for businesses with revenue below $5 million",
      "Establishes phased 0.25% annual tax reduction for larger corporations",
      "Creates 4-year implementation timeline for full tax reductions",
      "Requires biennial review of economic impact",
      "Allocates savings to economic development incentives"
    ],
    actionMessage: "⚠️ IMMEDIATE ACTION: House committee hearing scheduled for 3/18/2025",
    stageName: "Stage 3: Voted by Senate",
    progress: 42,
    lastAction: "Passed Senate with 26 Yeas, 5 Nays on 3/10/2025"
  }
];

const LegislativeBillTrackerView = ({ data, mode = 'view', onError }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);
  const billsRef = useRef(null);
  const [expandedHighlights, setExpandedHighlights] = useState(new Set());
  const [activeFilters, setActiveFilters] = useState(new Set());

  // Destructure state for easier access
  const { allBills, isLoading, filters, sorting } = state;
  const { category, priority, stage } = filters;
  const { field, direction } = sorting;

  // Memoized filtered bills calculation
  const filteredBills = useMemo(() => {
    if (!allBills || allBills.length === 0) return [];
    
    // Get only bills with valid IDs to prevent rendering issues
    const validBills = allBills.filter(bill => bill && bill.id);
    
    if (validBills.length !== allBills.length) {
      console.warn('WARNING: Some bills have no IDs');
    }
    
    // First apply category filters from activeFilters
    let filtered = validBills;
    if (activeFilters.size > 0) {
      filtered = filtered.filter(bill => 
        bill.categories.some(category => activeFilters.has(category))
      );
    }
    
    // Then apply all other filters in one pass with strict validation
    filtered = filtered.filter(bill => {
      // Category filter
      if (category !== 'all') {
        const billCategories = Array.isArray(bill.categories) 
          ? bill.categories.map(c => c.replace(/\s+/g, '-')) // Convert spaces to hyphens
          : [];
          
        if (!billCategories.includes(category)) return false;
      }
      
      // Priority filter
      if (priority !== 'all') {
        const billPriority = bill.priority === 'priority' ? 'priority-action' :
                            bill.priority === 'upcoming' ? 'upcoming-action' :
                            'normal';
        if (priority !== billPriority) return false;
      }
      
      // Stage filter - ensure proper stage format comparison
      if (stage !== 'all') {
        const billStage = bill.stage.startsWith('stage-') ? bill.stage : `stage-${bill.stage}`;
        if (billStage !== stage) return false;
      }
      
      return true;
    });
    
    // Apply sorting (only if there are results)
    if (filtered.length === 0) return filtered;
    
    // Create a fresh array for sorting and ensure stable sort
    return [...filtered].sort((a, b) => {
      if (!a || !b) return 0;
      
      let comparison = 0;
      
      switch (field) {
        case 'impact':
          comparison = (Number(a.impactValue) || 0) - (Number(b.impactValue) || 0);
          break;
        case 'stage':
          // Extract stage numbers for comparison
          const aStageNum = parseInt(a.stage.replace('stage-', ''), 10) || 0;
          const bStageNum = parseInt(b.stage.replace('stage-', ''), 10) || 0;
          comparison = aStageNum - bStageNum;
          break;
        case 'lastActionDate':
          // Convert dates to timestamps for comparison, handle null/invalid dates
          const aLastActionDate = a.lastActionDate ? a.lastActionDate.getTime() : -Infinity;
          const bLastActionDate = b.lastActionDate ? b.lastActionDate.getTime() : -Infinity;
          comparison = aLastActionDate - bLastActionDate;
          break;
        case 'date':
          const aDate = a.filingDate instanceof Date ? a.filingDate.getTime() : 0;
          const bDate = b.filingDate instanceof Date ? b.filingDate.getTime() : 0;
          comparison = aDate - bDate;
          break;
        case 'title':
          // Split bill numbers into prefix and number parts
          const [aPrefix, aNum] = String(a.title || '').match(/([A-Za-z]+)\s*(\d+)/)?.slice(1) || ['', '0'];
          const [bPrefix, bNum] = String(b.title || '').match(/([A-Za-z]+)\s*(\d+)/)?.slice(1) || ['', '0'];
          
          // First compare prefixes
          if (aPrefix !== bPrefix) {
            return aPrefix.localeCompare(bPrefix);
          }
          
          // Then compare numbers numerically
          comparison = parseInt(aNum, 10) - parseInt(bNum, 10);
          break;
        default:
          comparison = 0;
      }
      
      // For equal values, sort by ID to ensure stable sort order
      if (comparison === 0) {
        return String(a.id || '').localeCompare(String(b.id || ''));
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
  }, [allBills, category, priority, stage, field, direction, activeFilters]);

  // Effect for CSV loading
  useEffect(() => {
    if (!data?.csvUrl) {
      // Use sample data when no CSV URL is provided
      dispatch({ type: ACTIONS.SET_BILLS, payload: sampleBills });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      return;
    }

    // Fetch and process CSV data with error handling
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    
    const handleCSVError = (error) => {
      console.error('Error loading CSV:', error);
      if (onError) {
        onError(error);
      }
      // Use sample data as fallback on error
      dispatch({ type: ACTIONS.SET_BILLS, payload: sampleBills });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    };

    try {
      Papa.parse(data.csvUrl, {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data && Array.isArray(results.data)) {
            try {
              const processedBills = results.data
                .map(processBillData)
                .filter(Boolean);
              
              const uniqueBills = removeDuplicateBills(processedBills);
              dispatch({ type: ACTIONS.SET_BILLS, payload: uniqueBills });
            } catch (err) {
              handleCSVError(err);
            }
          }
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        },
        error: handleCSVError,
        beforeFirstChunk: (chunk) => {
          // Validate CSV structure
          if (!chunk || typeof chunk !== 'string') {
            throw new Error('Invalid CSV format');
          }
          return chunk;
        }
      });
    } catch (err) {
      handleCSVError(err);
    }
  }, [data?.csvUrl, onError]);

  // Helper function to remove duplicate bill IDs
  const removeDuplicateBills = (bills) => {
    // Create a Map to keep only one bill per ID (last one wins)
    const billMap = new Map();
    
    bills.forEach(bill => {
      if (bill && bill.id) {
        billMap.set(bill.id, bill);
      }
    });
    
    // Convert back to array
    return Array.from(billMap.values());
  };
  
  // Helper function to get category ID from display name
  const getCategoryId = (displayName) => {
    // First try to find the category in our filterOptions
    const category = filterOptions.categories.find(cat => 
      cat.name === displayName || cat.id === displayName
    );
    if (category) return category.id;
    
    // If not found, convert the display name to an ID format
    return displayName.replace(/\s+/g, '-');
  };

  // Helper function to get category display name from ID
  const getCategoryDisplayName = (categoryId) => {
    const category = filterOptions.categories.find(cat => cat.id === categoryId);
    return category ? category.name : categoryId.replace(/-/g, ' ');
  };

  // Process bill data with consistent category handling
  const processBillData = (bill) => {
    try {
      if (!bill.BillNumber) {
        throw new Error('Missing bill number');
      }
    
      // Categories - ensure valid array handling with proper ID conversion
      let categoriesArray = [];
      if (bill.Categories) {
        categoriesArray = bill.Categories.includes(',') 
          ? bill.Categories.split(',')
              .map(c => c.trim())
              .filter(Boolean)
              .map(c => getCategoryId(c))
          : [getCategoryId(bill.Categories.trim())];
      }
      
      // Priority - validate enumeration
      let priority = 'normal';
      if (bill.IsPriorityAction === 'TRUE') {
        priority = 'priority';
      } else if (bill.IsUpcomingAction === 'TRUE') {
        priority = 'upcoming';
      }
      
      // Impact - validate enumeration
      const impactMap = { 'High': 3, 'Medium': 2, 'Low': 1 };
      const impactValue = impactMap[bill.ImpactLevel] || 1;
      
      // Filing date - with robust validation
      let filingDate = null;
      try {
        if (bill.FilingDate) {
          filingDate = new Date(bill.FilingDate);
          // Verify date is valid
          if (isNaN(filingDate.getTime())) {
            filingDate = null;
          }
        }
      } catch (e) {
        filingDate = null;
      }
      
      // Ensure stage is a valid string
      const stage = bill.StageNumber ? String(bill.StageNumber).trim() : '1';
      
      // Parse last action date with robust validation
      let lastActionDate = null;
      try {
        if (bill.LastActionDate) {
          lastActionDate = new Date(bill.LastActionDate);
          if (isNaN(lastActionDate.getTime())) {
            lastActionDate = null;
          }
        }
      } catch (e) {
        lastActionDate = null;
      }

      // Calculate days since last action
      let daysSinceLastAction = null;
      let isInactive = false;
      if (lastActionDate) {
        const today = new Date();
        const timeDiff = today.getTime() - lastActionDate.getTime();
        daysSinceLastAction = Math.floor(timeDiff / (1000 * 3600 * 24));
        // Bill is considered inactive if it's been 30+ days since last action and not in final stage
        isInactive = daysSinceLastAction >= 30 && stage !== '7';
      }
      
      // Create properly validated bill object
      return {
        id: bill.BillNumber, // Primary key
        title: bill.BillNumber || 'Unknown Bill',
        link: bill.BillLink || '#',
        impact: (bill.ImpactLevel || 'medium').toLowerCase(),
        impactValue,
        categories: categoriesArray,
        categoryDisplayNames: categoriesArray.map(getCategoryDisplayName),
        stage,
        filingDate,
        filingInfo: filingDate && bill.Sponsor 
          ? `Filed by ${bill.Sponsor} on ${bill.FilingDate}` 
          : (bill.Sponsor ? `Filed by ${bill.Sponsor}` : ''),
        priority,
        highlights: [
          bill.KeyHighlight1,
          bill.KeyHighlight2,
          bill.KeyHighlight3,
          bill.KeyHighlight4,
          bill.KeyHighlight5
        ].filter(Boolean), // Remove empty highlights
        actionMessage: bill.ActionNeeded 
          ? `${priority === 'priority' ? '⚠️ IMMEDIATE ACTION: ' : 'Upcoming: '}${bill.ActionNeeded}${bill.ActionDate && bill.ActionDate !== 'N/A' ? ` (${bill.ActionDate})` : ''}`
          : '',
        stageName: `Stage ${stage}: ${bill.CurrentStage || 'Filed'}`,
        progress: parseInt(bill.ProgressPercentage, 10) || 0,
        lastAction: bill.LastAction || 'No action recorded',
        lastActionDate,
        daysSinceLastAction,
        isInactive
      };
    } catch (error) {
      console.error(`Error processing bill ${bill.BillNumber || 'unknown'}`, error);
      
      // Return minimal valid bill object with guaranteed unique ID
      return {
        id: bill.BillNumber || `unknown-${Math.random().toString(36).substr(2, 9)}`,
        title: bill.BillNumber || 'Unknown Bill',
        categories: [],
        categoryDisplayNames: [],
        highlights: [],
        priority: 'normal',
        stage: '1',
        progress: 0,
        isInactive: false
      };
    }
  };
  
  // Event handlers
  const handleFilterChange = (filterName, value) => {
    if (filterName === 'category') {
      // For category filters, use the activeFilters Set
      setActiveFilters(prev => {
        const newFilters = new Set(prev);
        if (value === 'all') {
          newFilters.clear(); // Clear all filters when "All Categories" is selected
        } else {
          // If clicking an active filter, remove it; otherwise add it
          if (newFilters.has(value)) {
            newFilters.delete(value);
          } else {
            newFilters.add(value);
          }
        }
        return newFilters;
      });
    } else {
      // For other filters, use the existing reducer logic
      dispatch({ 
        type: ACTIONS.SET_FILTER, 
        payload: { name: filterName, value } 
      });
    }
  };
  
  const handleSortChange = (field) => {
    dispatch({ 
      type: ACTIONS.SET_SORT, 
      payload: { name: 'field', value: field } 
    });
  };
  
  const handleDirectionChange = () => {
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    dispatch({ 
      type: ACTIONS.SET_SORT, 
      payload: { name: 'direction', value: newDirection } 
    });
  };
  
  const clearAllFilters = () => {
    dispatch({ type: ACTIONS.CLEAR_FILTERS });
  };
  
  // Helper functions for rendering UI elements
  const renderStageDots = (currentStage) => {
    const stageNumber = parseInt(currentStage, 10);
    const dots = [];
    
    for (let i = 1; i <= 7; i++) {
      dots.push(
        <li key={`stage-${i}`}>
          <div 
            className={`stage-dot ${i <= stageNumber ? 'active' : 'inactive'}`} 
            title={`Stage ${i}: ${filterOptions.stages.find(s => s.id === i.toString())?.name.split(': ')[1] || ''}`}
          ></div>
        </li>
      );
    }
    
    return dots;
  };

  // Style helper functions
  const getTagColorClass = (category) => {
    const colorMap = {
      'Business Taxation': 'tax-tag',
      'Business Courts': 'courts-tag',
      'Financial Institutions': 'financial-tag',
      'Business Entities': 'entities-tag',
      'Foreign Property Ownership': 'foreign-tag',
      'Real Estate': 'realestate-tag',
      'Securities': 'securities-tag',
      'Business Liability': 'liability-tag',
      'AI and Technology': 'ai-tag'
    };
    
    return colorMap[category] || 'default-tag';
  };

  const getStageColorClass = (stage) => {
    const stageNumber = parseInt(stage, 10);
    return `stage-${stageNumber}-bg`;
  };

  const getPriorityCardClass = (priority) => {
    const priorityMap = {
      'priority': 'priority-action-card',
      'upcoming': 'upcoming-action-card',
      'normal': ''
    };
    
    return priorityMap[priority] || '';
  };

  const getImpactColorClass = (impact) => {
    const impactMap = {
      'high': 'high-impact',
      'medium': 'medium-impact',
      'low': 'low-impact'
    };
    
    return impactMap[impact] || '';
  };

  // Add function to toggle highlights expansion
  const toggleHighlights = (event, billId) => {
    event.preventDefault(); // Prevent the card link from being followed
    event.stopPropagation(); // Prevent event bubbling
    setExpandedHighlights(prev => {
      const newSet = new Set(prev);
      if (newSet.has(billId)) {
        newSet.delete(billId);
      } else {
        newSet.add(billId);
      }
      return newSet;
    });
  };

  // Loading state UI
  if (isLoading) {
    return (
      <div className="ui segment">
        <div className="ui active dimmer">
          <div className="ui text loader">Loading...</div>
        </div>
      </div>
    );
  }

  // Main component UI
  return (
    <div className="legislative-bill-tracker">
      <h1 className="block-title">
        {intl.formatMessage(messages.title)}
      </h1>
      
      {/* Filters and Sort container */}
      <div style={filterStyles.filtersContainer}>
        {/* Filters Section */}
        <div className="collapsible-section filters-section">
          <button 
            className="collapsible-header"
            onClick={() => dispatch({ type: ACTIONS.TOGGLE_FILTERS })}
            aria-expanded={filters.isOpen}
            aria-controls="filters-content"
          >
            <div className="header-content">
              <span className="toggle-icon">
                {filters.isOpen ? '▼' : '▶'}
              </span>
              <span>Filter Bills</span>
              <span className="filter-summary">
                {filteredBills.length} of {allBills.length} bills shown
              </span>
            </div>
          </button>
          
          {filters.isOpen && (
            <div 
              className="collapsible-content"
              id="filters-content"
              role="region"
              aria-labelledby="filters-header"
            >
              {/* Category filter */}
              <div style={filterStyles.filterSection}>
                <div style={filterStyles.filterHeader}>
                  <h4 style={filterStyles.filterTitle}>
                    {intl.formatMessage(messages.filterByCategoryTitle)}
                  </h4>
                </div>
                <div className="filter-chips">
                  {filterOptions.categories.map(categoryOption => (
                    <div 
                      key={categoryOption.id}
                      onClick={() => handleFilterChange('category', categoryOption.id)}
                      className={`filter-chip ${activeFilters.has(categoryOption.id) || (categoryOption.id === 'all' && activeFilters.size === 0) ? 'active' : ''}`}
                      data-testid={`category-filter-${categoryOption.id}`}
                    >
                      {categoryOption.name}
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Priority filter */}
              <div style={filterStyles.filterSection}>
                <div style={filterStyles.filterHeader}>
                  <h4 style={filterStyles.filterTitle}>
                    {intl.formatMessage(messages.filterByPriorityTitle)}
                  </h4>
                </div>
                <div className="filter-chips">
                  {filterOptions.priorities.map(priorityOption => (
                    <div 
                      key={priorityOption.id}
                      onClick={() => handleFilterChange('priority', priorityOption.id)}
                      className={`filter-chip ${priority === priorityOption.id ? 'active' : ''}`}
                      data-testid={`priority-filter-${priorityOption.id}`}
                    >
                      {priorityOption.name}
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Stage filter */}
              <div style={filterStyles.filterSection}>
                <div style={filterStyles.filterHeader}>
                  <h4 style={filterStyles.filterTitle}>
                    {intl.formatMessage(messages.filterByStageTitle)}
                  </h4>
                </div>
                <div className="filter-chips">
                  {filterOptions.stages.map(stageOption => (
                    <div 
                      key={stageOption.id}
                      onClick={() => handleFilterChange('stage', stageOption.id)}
                      className={`filter-chip ${stage === stageOption.id ? 'active' : ''}`}
                      data-testid={`stage-filter-${stageOption.id}`}
                    >
                      {stageOption.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Sort Section */}
        <div className="collapsible-section sort-section">
          <button 
            className="collapsible-header"
            onClick={() => dispatch({ type: ACTIONS.TOGGLE_SORT })}
            aria-expanded={sorting.isOpen}
            aria-controls="sort-content"
          >
            <div className="header-content">
              <span className="toggle-icon">
                {sorting.isOpen ? '▼' : '▶'}
              </span>
              <span>Sort Bills</span>
              <span className="sort-summary">
                {field && `Sorted by ${filterOptions.sortOptions.find(opt => opt.value === field)?.text || field} (${direction})`}
              </span>
            </div>
          </button>
          
          <div 
            className={`collapsible-content ${sorting.isOpen ? 'visible' : ''}`}
            id="sort-content"
            role="region"
            aria-labelledby="sort-header"
            style={{ display: sorting.isOpen ? 'block' : 'none' }}
          >
            {/* Sort controls */}
            <div style={filterStyles.filterSection}>
              <div style={filterStyles.filterHeader}>
                <h4 style={filterStyles.filterTitle}>
                  {intl.formatMessage(messages.sortByTitle)}
                </h4>
              </div>
              <div className="filter-chips">
                {filterOptions.sortOptions.map(sortOption => (
                  <div 
                    key={sortOption.value}
                    onClick={() => handleSortChange(sortOption.value)}
                    className={`filter-chip ${field === sortOption.value ? 'active' : ''}`}
                    data-testid={`sort-option-${sortOption.value}`}
                  >
                    {sortOption.text}
                  </div>
                ))}
              </div>
              <div style={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '12px'
              }}>
                <button 
                  className="direction-toggle-button"
                  onClick={handleDirectionChange}
                  title={direction === 'asc' ? 'Sort Ascending' : 'Sort Descending'}
                  data-testid="sort-direction-button"
                >
                  {direction === 'asc' ? 'Ascending ↑' : 'Descending ↓'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters summary - Now below sort section */}
        {(category !== 'all' || priority !== 'all' || stage !== 'all' || activeFilters.size > 0) && (
          <div className="active-filters-bar">
            <div className="active-filters-content">
              {/* Show categories from activeFilters Set */}
              {Array.from(activeFilters).map(categoryId => {
                const categoryOption = filterOptions.categories.find(cat => cat.id === categoryId);
                return (
                  <div key={categoryId} className="filter-label">
                    <span>{categoryOption?.name || categoryId}</span>
                    <button 
                      className="close-button"
                      onClick={() => handleFilterChange('category', categoryId)}
                      aria-label={`Remove ${categoryOption?.name || categoryId} filter`}
                    >
                      ×
                    </button>
                  </div>
                );
              })}
              
              {priority !== 'all' && (
                <div className="filter-label">
                  <span>{priority}</span>
                  <button 
                    className="close-button"
                    onClick={() => handleFilterChange('priority', 'all')}
                    aria-label={`Remove ${priority} filter`}
                  >
                    ×
                  </button>
                </div>
              )}
              
              {stage !== 'all' && (
                <div className="filter-label">
                  <span>{stage}</span>
                  <button 
                    className="close-button"
                    onClick={() => handleFilterChange('stage', 'all')}
                    aria-label={`Remove ${stage} filter`}
                  >
                    ×
                  </button>
                </div>
              )}
              
              <Button 
                size="mini"
                basic
                className="clear-filters-button"
                onClick={() => {
                  clearAllFilters();
                  setActiveFilters(new Set());
                }}
              >
                Clear
              </Button>
            </div>
          </div>
        )}
      </div>
      
      {/* Bills grid with ref for validation */}
      <div className="bills-grid" ref={billsRef}>
        {filteredBills.length > 0 ? (
          filteredBills.map(bill => (
            <a 
              key={bill.id}
              href={bill.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`bill-card ${getPriorityCardClass(bill.priority)}`}
              data-testid={`bill-${bill.id}`}
            >
              <div className="bill-header">
                <span className="bill-title">
                  {bill.title}
                </span>
                <div className={`impact-indicator ${getImpactColorClass(bill.impact)}`}>
                  {bill.impact.toUpperCase()} IMPACT
                </div>
              </div>

              <div className="bill-categories">
                {bill.categoryDisplayNames.map((displayName, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`category-tag ${bill.categories[index].toLowerCase()}-tag`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFilterChange('category', bill.categories[index]);
                    }}
                    aria-pressed={activeFilters.has(bill.categories[index])}
                  >
                    {displayName}
                  </button>
                ))}
              </div>

              <div className="stage-indicator">
                <div className={`stage-label ${getStageColorClass(bill.stage)}`}>
                  {bill.stageName}
                </div>
                <ul className="stage-dots">
                  {renderStageDots(bill.stage)}
                </ul>
              </div>

              {bill.actionMessage && (
                <div className={`action-message ${bill.priority === 'priority' ? 'urgent' : ''}`}>
                  {bill.actionMessage}
                </div>
              )}

              {bill.isInactive && (
                <div className="inactivity-notice">
                  <Icon name="clock outline" />
                  {bill.daysSinceLastAction} days since last action
                </div>
              )}

              <div className="bill-content">
                <div className="last-action">
                  <strong>{intl.formatMessage(messages.lastActionLabel)}</strong>
                  <p>
                    {bill.lastAction}
                    {bill.lastActionDate && (
                      <span className="last-action-date">
                        {new Date(bill.lastActionDate).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        })}
                      </span>
                    )}
                  </p>
                </div>

                <div className="highlights-section">
                  <strong>{intl.formatMessage(messages.keyHighlightsLabel)}</strong>
                  <ul className="highlights-list">
                    {bill.highlights.slice(0, expandedHighlights.has(bill.id) ? undefined : 2).map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                  {bill.highlights.length > 2 && (
                    <button 
                      className="expand-highlights-button"
                      onClick={(e) => toggleHighlights(e, bill.id)}
                      aria-expanded={expandedHighlights.has(bill.id)}
                    >
                      {expandedHighlights.has(bill.id) ? 'Show Less' : `Show ${bill.highlights.length - 2} More`}
                    </button>
                  )}
                </div>

                {bill.filingInfo && (
                  <div className="filing-info">
                    {bill.filingInfo}
                  </div>
                )}
              </div>
            </a>
          ))
        ) : (
          <Message warning>
            <Message.Header>No Results Found</Message.Header>
            <p>{intl.formatMessage(messages.noResultsMessage)}</p>
          </Message>
        )}
      </div>
    </div>
  );
};

export default LegislativeBillTrackerView;