import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import { Menu, Tab, Container, Button, Form, Image, Icon } from 'semantic-ui-react';
import { Toast } from '@plone/volto/components';
import { getUser, updateUser, getUserSchema } from '@plone/volto/actions';
import { FileWidget } from '@plone/volto/components';
import { WysiwygWidget } from '@plone/volto/components';
import axios from 'axios';
import { Link } from 'react-router-dom';

const messages = defineMessages({
  account: {
    id: 'Account',
    defaultMessage: 'Account',
  },
  contactInfo: {
    id: 'Contact Info',
    defaultMessage: 'Contact Info',
  },
  onlinePresence: {
    id: 'Online Presence',
    defaultMessage: 'Online Presence',
  },
  details: {
    id: 'Details',
    defaultMessage: 'Details',
  },
  saved: {
    id: 'Changes saved',
    defaultMessage: 'Changes saved',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  managePortrait: {
    id: 'Manage Portrait',
    defaultMessage: 'Manage Portrait',
  },
});

const PersonalInformation = ({ closeMenu }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [portraitUrl, setPortraitUrl] = useState('');
  const user = useSelector((state) => state.users.user);
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const loading = useSelector((state) => state.users.update.loading);
  const userschema = useSelector((state) => state.userschema);
  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserSchema());
    if (userId) {
      setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      setFormData({
        ...user,
        email_private: !!user.email_private,
        phone_private: !!user.phone_private,
        site_subscription: !!user.site_subscription,
        text: user.text && typeof user.text === 'object' ? user.text.data : user.text || '',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchPracticeAreas = async () => {
      try {
        const response = await fetch(
          '++api++/@vocabularies/txbizlaw.plone.legal_practice_areas',
        );
        const data = await response.json();
        setPracticeAreas(
          data.items.map((item) => ({ value: item.token, label: item.title })),
        );
      } catch (error) {
        console.error('Error fetching practice areas:', error);
      }
    };

    fetchPracticeAreas();
  }, []);

  const onSubmit = () => {
    const data = { ...formData };
    // Remove fields that shouldn't be sent
    const {
      id,
      username,
      roles,
      portrait,
      '@id': atId,
      '@type': atType,
      ...userData
    } = data;

    // Clean up text fields that might be null or undefined
    Object.keys(userData).forEach(key => {
      if (userData[key] === null || userData[key] === undefined) {
        userData[key] = '';
      }
      // Ensure boolean fields are actually booleans
      if (key === 'email_private' || key === 'phone_private' || key === 'site_subscription') {
        userData[key] = !!userData[key];
      }
      // Handle rich text fields - just send the content directly
      if (key === 'text') {
        userData[key] = typeof userData[key] === 'object' ? userData[key].data : userData[key];
      }
    });

    fetch(`/++api++/@users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
      credentials: 'include',
    })
    .then(response => {
      if (!response.ok) {
        // Try to get error message from response
        return response.text().then(text => {
          try {
            // Try to parse as JSON
            const data = JSON.parse(text);
            throw new Error(data.message || 'Failed to update profile');
          } catch (e) {
            // If parsing fails, use the text directly or a default message
            throw new Error(text || 'Failed to update profile');
          }
        });
      }
      
      // For successful responses, only try to parse JSON if there's content
      return response.status !== 204 ? response.json() : null;
    })
    .then(() => {
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.saved)}
        />,
      );
      
      // Refresh user data after successful update
      dispatch(getUser(userId));
    })
    .catch((error) => {
      console.error('Error:', error);
      toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={error.message || intl.formatMessage(messages.error)}
        />,
      );
    });

    if (closeMenu) closeMenu();
  };

  const onCancel = () => {
    if (closeMenu) closeMenu();
    else history.goBack();
  };

  const handleInputChange = useCallback((e, data) => {
    const { name, value } = data || e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === null ? '' : value,
    }));
  }, []);

  const handleCheckboxChange = (e, { name, checked }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const fieldInfo = {
    fullname: {
      label: 'Full Name',
      type: 'text',
    },
    email: {
      label: 'Email',
      type: 'email',
    },
    location: {
      label: 'Office Location',
      type: 'text',
    },
    mailing_address: {
      label: 'Business Mailing Address',
      type: 'text',
    },
    phone: {
      label: 'Phone Number',
      type: 'text',
    },
    fax: {
      label: 'Fax Number',
      type: 'text',
    },
    cell: {
      label: 'Cell Number',
      type: 'text',
    },
    email_private: {
      label: 'Make My Email Private',
      type: 'checkbox',
    },
    phone_private: {
      label: 'Make My Phone Private',
      type: 'checkbox',
    },
    site_subscription: {
      label: 'Email Subscription',
      type: 'checkbox',
    },
    home_page: {
      label: 'Website',
      type: 'text',
    },
    linkedin: {
      label: 'LinkedIn Page',
      type: 'text',
    },
    facebook: {
      label: 'Facebook Page',
      type: 'text',
    },
    personal_urls: {
      label: 'Personal URLs',
      type: 'url',
    },
    description: {
      label: 'Short Practice Description',
      type: 'textarea',
    },
    practice_area: {
      label: 'Legal Practice Area',
      type: 'select',
    },
    text: {
      label: 'About You and Your Practice',
      type: 'richtext',
      help: 'Share detailed information about your legal background, expertise, and practice areas. This will help other members understand your professional focus and experience.',
    },
    full_text: {
      label: 'Full Search Text',
      type: 'textarea',
    },
    city_search: {
      label: 'City Search Text',
      type: 'text',
    },
    practice_search: {
      label: 'Practice Search Text',
      type: 'text',
    }
  };

  const renderField = (fieldName) => {
    const field = fieldInfo[fieldName];

    if (!field) {
      return null; // or handle the case where the field is not found
    }

    const commonProps = {
      name: fieldName,
      value: formData[fieldName] || '',
      onChange: handleInputChange,
    };

    switch (field.type) {
      case 'text':
        return <Form.Input {...commonProps} type="text" />;

      case 'email':
        return <Form.Input {...commonProps} type="email" />;

      case 'textarea':
        return <Form.TextArea {...commonProps} />;

      case 'checkbox':
        return (
          <Form.Checkbox
            label={field.label}
            name={fieldName}
            checked={!!formData[fieldName]}
            onChange={handleCheckboxChange}
          />
        );

      case 'select':
        if (fieldName === 'practice_area') {
          return (
            <Form.Field className="practice-area-field">
              <label>{field.label}</label>
              <Form.Dropdown
                placeholder="Select practice areas"
                fluid
                multiple
                search
                selection
                clearable
                className="practice-area-dropdown"
                name="practice_area"
                value={Array.isArray(formData.practice_area) ? formData.practice_area : []}
                options={practiceAreas.map((area) => ({
                  key: area.value,
                  value: area.value,
                  text: area.label,
                }))}
                onChange={(e, { value }) => {
                  setFormData((prevData) => ({
                    ...prevData,
                    practice_area: value,
                  }));
                }}
              />
            </Form.Field>
          );
        }
        return (
          <Form.Select
            {...commonProps}
            options={[
              { key: '', value: '', text: 'Select a practice area' },
              ...practiceAreas.map((area) => ({
                key: area.value,
                value: area.value,
                text: area.label,
              })),
            ]}
          />
        );

      case 'richtext':
        return (
          <Form.Field className="about-practice-editor">
            <label>{field.label}</label>
            {field.help && <p className="help-text">{field.help}</p>}
            <WysiwygWidget
              id={fieldName}
              name={fieldName}
              title={field.label}
              description={field.help}
              value={{
                data: formData[fieldName] || '',
                'content-type': 'text/html',
                encoding: 'utf8'
              }}
              onChange={(id, value) => {
                setFormData((prevData) => ({
                  ...prevData,
                  [id]: value?.data || '',
                }));
              }}
              wrapped={false}
              columns={12}
            />
          </Form.Field>
        );

      default:
        return <Form.Input {...commonProps} type="text" />;
    }
  };

  // Updated renderFormFields function
  const renderFormFields = (fields) => {
    return fields.map((fieldName) => {
      const field = fieldInfo[fieldName];
      // Skip label rendering for richtext fields as they handle their own labels
      const shouldRenderLabel = field?.type !== 'richtext';
      
      return (
        <div key={fieldName}>
          {shouldRenderLabel && <label>{field?.label}</label>}
          {renderField(fieldName)}
        </div>
      );
    });
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="account">
          {intl.formatMessage(messages.account)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            <div className="portrait-section">
              {portraitUrl && user?.portrait ? (
                <Image 
                  src={portraitUrl} 
                  alt="Current portrait" 
                  size="small" 
                  style={{ marginBottom: '1em' }} 
                />
              ) : (
                <div className="portrait-placeholder">
                  <Icon name="user circle" size="huge" />
                  <p>No portrait</p>
                </div>
              )}
              <Button 
                as={Link}
                to="/portrait-manager" 
                className="portrait-manager-button"
              >
                {intl.formatMessage(messages.managePortrait)}
              </Button>
            </div>
            {renderFormFields(['fullname', 'email', 'description'])}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="contactInfo">
          {intl.formatMessage(messages.contactInfo)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields([
              'location',
              'mailing_address',
              'phone',
              'fax',
              'cell',
              'email_private',
              'phone_private',
              'site_subscription',
            ])}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="onlinePresence">
          {intl.formatMessage(messages.onlinePresence)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields(['home_page', 'facebook', 'linkedin', 'personal_urls'])}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="details">
          {intl.formatMessage(messages.details)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields([
              'practice_area',
              'text',
              'full_text',
              'city_search',
              'practice_search',
            ])}
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    userschema?.loaded && (
      <Container className="personal-information">
        <style>
          {`
            .help-text {
              color: #666;
              font-size: 0.9em;
              margin-bottom: 0.5em;
              font-style: italic;
            }
            .practice-area-field {
              margin-bottom: 2em !important;
            }
            /* Add spacing between fields */
            .ui.form .field {
              margin-bottom: 1.5em !important;
            }
            /* Add extra spacing before the rich text editor */
            .about-practice-editor {
              margin-top: 3em !important;
              border-top: 1px solid #eee;
              padding-top: 2em;
              width: 100%;
            }
            .about-practice-editor label {
              font-size: 1.1em;
              font-weight: bold;
              margin-bottom: 1em;
              display: block;
            }
            .about-practice-editor .help-text {
              margin-bottom: 1.5em;
            }
            .about-practice-editor .DraftEditor-root {
              min-height: 300px;
              border: 1px solid #ddd;
              padding: 1em;
              box-sizing: border-box;
              width: 100%;
              background: white;
            }
            /* Improve spacing for practice area dropdown */
            .practice-area-dropdown {
              min-height: auto !important;
              margin-bottom: 2em !important;
            }
            .practice-area-dropdown .ui.label {
              margin: 2px 4px;
              padding: 4px 8px;
              font-size: 0.9em;
              background-color: #f3f4f5;
              border: 1px solid #e0e1e2;
              border-radius: 3px;
            }
            .practice-area-dropdown .ui.label .delete.icon {
              opacity: 0.6;
              margin-left: 6px;
            }
            .practice-area-dropdown .ui.label .delete.icon:hover {
              opacity: 1;
            }
            .practice-area-dropdown .dropdown.icon {
              margin-top: -0.5em !important;
            }
            .practice-area-dropdown input.search {
              margin: 0.2em !important;
              padding: 0.4em !important;
            }
            .practice-area-dropdown.ui.dropdown {
              padding: 0.4em;
              min-height: 2.7em;
              max-height: none;
              height: auto;
              overflow: visible;
            }
            .practice-area-dropdown .menu {
              margin-top: 0.5em !important;
              border-radius: 3px !important;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
            }
            .practice-area-dropdown .menu > .item {
              padding: 0.6em 1em !important;
            }
            .practice-area-dropdown.ui.multiple.dropdown > .label {
              margin-top: 0.2em;
              margin-bottom: 0.2em;
            }
            .about-practice-editor {
              margin-top: 1em;
              padding: 0;
              width: 100%;
            }
            .about-practice-editor .DraftEditor-root {
              min-height: 300px;
              border: 1px solid #ddd;
              padding: 1em;
              box-sizing: border-box;
              width: 100%;
            }
            .about-practice-editor .public-DraftEditor-content {
              min-height: 300px;
            }
            /* Override grid system for full width */
            .about-practice-editor .grid {
              margin: 0 !important;
            }
            .about-practice-editor .stretched.row {
              padding: 0 !important;
            }
            .about-practice-editor .stretched.row > .column {
              padding: 0 !important;
            }
            /* Hide the wrapper */
            .about-practice-editor .wrapper {
              display: none;
            }
            /* Remove extra padding from form fields */
            .ui.form .field {
              padding: 0;
              margin-bottom: 1em;
            }
            /* Remove padding from tab pane */
            .ui.tab.segment {
              padding-left: 0;
              padding-right: 0;
            }
            /* Ensure toolbar appears properly */
            .draftJsToolbar__toolbar__dNtBH {
              transform: translate(-50%) scale(1) !important;
              visibility: visible !important;
              z-index: 2;
            }
            .portrait-section {
              text-align: center;
              margin-bottom: 2em;
              padding: 1em;
              background: #f9f9f9;
              border-radius: 4px;
            }
            .portrait-manager-button {
              margin-top: 1em !important;
            }
            .portrait-placeholder {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-bottom: 1em;
              color: #999;
            }
            .portrait-placeholder i {
              font-size: 5em;
              margin-bottom: 0.5em;
            }
            .portrait-placeholder p {
              margin: 0;
              font-size: 0.9em;
            }
          `}
        </style>
        <Tab panes={panes} />
        <div className="form-actions">
          <Button primary onClick={onSubmit} disabled={loading}>
            {intl.formatMessage(messages.save)}
          </Button>
          <Button onClick={onCancel}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </div>
      </Container>
    )
  );
};

PersonalInformation.propTypes = {
  closeMenu: PropTypes.func,
};

export default PersonalInformation;
